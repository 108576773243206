import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import Header from '../components/Header';
import Share from '../components/Share';

import {
  SectionComponent,
  HeadlineComponent,
  ParagraphComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';

export const query = graphql`
  query($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      date(formatString: "MMMM Do, YYYY")
      slug
      cover {
        fluid(maxWidth: 980, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
    }
  }
`;

const Content = styled.div`
  margin: 3rem auto;
  max-width: 1000px;
  h1,
  h2,
  h3 {
    margin-bottom: 20px;
    font-weight: 500;
  }
  p {
    text-align: left;
    margin-bottom: 1rem;
  }
  b {
    font-weight: 500;
  }
  hr {
    margin-bottom: 1rem;
  }
  img {
    max-width: 800px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  ol {
    margin-left: 2rem;
  }
`;

const Blog = ({ data }) => {
  const options = {
    renderNode: {
      'embedded-asset-block': ({ data: { target } }) => {
        const alt = target.fields.title['en-US'];
        const { url } = target.fields.file['en-US'];
        return <img alt={alt} src={url} />;
      },
    },
  };

  return (
    <Layout>
      <SEO
        title="Latest Poker News"
        link="https://teampoker.com/gallery"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <SectionComponent mg="1.5rem">
        <HeadlineComponent>{data.contentfulBlog.title}</HeadlineComponent>
        <ParagraphComponent>{data.contentfulBlog.date}</ParagraphComponent>
        <Content>
          {documentToReactComponents(data.contentfulBlog.body.json, options)}
        </Content>
      </SectionComponent>
      <Share
        socialConfig={{
          twitterHandle: '@teampoker',
          config: {
            url: `https://teampoker.com/blog/${data.contentfulBlog.slug.replace(
              /\s/g,
              '-'
            )}`,
            title: data.contentfulBlog.title,
          },
        }}
        tags={['teampoker', 'poker', 'casino', 'pokerteam', 'teampoker']}
      />
    </Layout>
  );
};

export default Blog;
